<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="12">

				<CCard>
    			<CCardBody>
						<TheTableHeader
							:header="'DATA RUANGAN'"
							:subHeader="'DAFTAR RUANGAN DI DATA CENTER LINTASARTA .'"
							buttonShow
							:buttonText="'Add New'"
							:buttonUrl="'rooms/form/0'"
						/>
					</CCardBody>
				</CCard>

				<CCard>
					<CCardBody>
						<CDataTable
							hover
							striped
							border
							small
							tableFilter
							sorter
							itemsPerPageSelect
							:items="rooms"
							:fields="fields"
							:items-per-page="5"
							pagination
							:loading="isLoading"
						>
							<template #id="{item}">
								<td align="center">{{rooms.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
							</template>
							<template #created_at="{item}">
								<td align="center">
									<p>{{item.created_at | formatDate}}  {{item.created_at | formatTime}}</p>
								</td>
							</template>
							<template #action="{item}">
            		<td align="center">
              		<CButton color="primary" variant="outline" square size="sm" @click="editRoom(item.id)">Edit</CButton> &nbsp; 
									<CButton color="danger" variant="outline" square size="sm" @click="removeRoom(item.id)">Delete</CButton>
            		</td>
          		</template>
						</CDataTable>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>	
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'TableMasterRoom',
	components: { TheTableHeader },
	data () {
		return {
			role: localStorage.getItem('role'),
			rooms: [],
			fields: [{ key: 'id', label: 'No' }, { key: 'site_name', label: 'Data Center' }, { key: 'room_name', label: 'Room Name' }, 'created_at', 'action'],
			currentPage: 1,
			perPage: 5,
			totalRows: 1,
			isLoading: true,
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Ruangan',
        }
      ],
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goCreate(){
      this.$router.push({ path: 'rooms/form/0' });
    },
		getBadge (status) {
			return status === 'Active' ? 'success' : 'danger'
		},
		getRooms (){
			let self = this;
			return http.get('/rooms')
			.then(function (response) {
				self.rooms = response.data.data;
				self.isLoading = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		editRoom(id) {
      this.$router.push({ path: 'rooms/form/' + id });
    },
		removeRoom(id) {
			let self = this;
			if (confirm('Yakin Ingin Menghapus ?')) {
    		return http.delete('/rooms/' + id)
					.then(function (response) {
						self.getRooms();
						self.toast('Berhasil Menghapus Ruangan Dari Daftar.', 'info');
						console.log(response);
					}).catch(function (error) {
						console.log(error);
					});
  		}
		}
	},
	mounted: function(){
		this.getRooms();
	}
}

</script>
